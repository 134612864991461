@font-face {
    font-family: BIREVA;
    src: url("fonts/BIREVA.otf") format("opentype");
}

.panel {
    width: 100vw;
    min-height: 100vh;
    background-color: #EEEBE5;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: flex;
}

.bb-style {
    color: white;
    background-color: #273045;
    border-radius: 7px;
    padding: 10px;
    margin-top: 15px;
}

.list-container {
    width: 30%;
    max-height: 80%;
    margin: auto;
}

.item {
    display: flex;
}

.item-img {
    width: 150px;
}

.item-content {
    width: 100%;
}

.item-top {
    position: relative;
    height: 25px;
}

.item-top h3 {
    font-family: Lucida Console;
    font-weight: 400;
    font-size: 17px;

    margin: 0;
    margin-left: 15px;
    max-height: 5px;
}

.item-description {
    width: 94%;
    height: 100px;

    font-size: 13px;
    margin-left: 15px;
    overflow: auto;
    opacity: 70%;
}

.item-bottom {
    width: 95%;
    margin-top: 5px;
    height: 20px;
    margin-left: 15px;
    position: relative;
}

.item-bottom span {
    background-color: #3d4a69;
    border-radius: 5px;
    padding: 3px;
    font-size: 12px;
    color: #ffffffa9;
}

.btn-download {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #7167EC;
    color: white;
    font-size: 15px;
    width: 120px;
    text-align: center;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
}

.btn-download:hover {
    transition: 400ms;
    border-radius: 1px solid #7167EC;
    background-color: white;
    color: #7167EC;
}

.Modal {
    width: 500px;
    max-width: 95vw;
    height: 500px;
    max-height: 100vh;
    border-radius: 10px;
    background-color: white;
    color: rgb(211, 211, 211);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.modal-container input {
    border: unset;
    color: rgb(211, 211, 211);
}

.modal-container input::placeholder {
    color: #919191;
}

.modal-top {
    position: relative;
    font-family: Verdana;
    font-size: 1.5rem;
    padding-left: 5px;
    padding-bottom: 2px;
    color: rgb(146, 146, 146);
    border-radius: 10px 10px 0 0;
    background: #EEEBE5;
}

.modal-content {
    color: rgb(146, 146, 146);
    padding: 8px;
    max-height: 402px;
    overflow: auto;
}

.modal-content img {
    max-width: 100%;
    cursor: zoom-in;
}

.modal-content span {
    background-color: #EEEBE5;
    padding: 1px 6px 1px 6px;
    border-radius: 3px;
}

.warning {
    color: red;
    background-color: rgba(255, 0, 0, 0.26);
    padding: 10px;
    border-radius: 5px;
}

.modal-close {
    height: 20px;
    width: 20px;

    position: absolute;
    right: 0;
    top: 0;

    cursor: pointer;
    margin: 5px;
    background-image: url("./images/close.png");
    background-size: contain;
    opacity: 80%;
}

.modal-close:hover {
    transition: 400ms;
    opacity: 100%;
}

.modal-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}

.btn-valid {
    position: absolute;
    right: 10px;
    top: 10px;
    color: rgb(80, 80, 80);
    font-size: 15px;
    width: 120px;
    max-width: 15vw;
    text-align: center;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    border: 1px solid rgba(130,140,171,0.3);
}

.btn-valid:hover {
    transition: 200ms;
    border: 1px solid rgba(26, 27, 31, 0.3);
}

.logout-btn {
    z-index: 500;
    position: fixed;
    right: 5px;
    top: 5px;
    cursor: pointer;
    color: #273045;
}

.list-packages {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

}

.package-element {
    color: black;
    background-color: white;
    border-radius: 10px;
    width: 400px;
    max-width: 100vw;
    height: 700px;
    max-height: 100vh;
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.2);
    margin: 20px;
    position: relative;
}

.pack-top {
    width: 100%;
    height: 220px;
    border-radius: 10px 10px 0 0px;
}

.pack-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.pack-top-info {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background-color: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.45) 40%, rgba(0,0,0,0) 100%);

}
.pack-top-info h2 {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 80%;
    font-family: BIREVA;
    font-size: 0.7rem;
    margin: 0;
    margin-bottom: 10px;
    margin-left: 10px;  
}

.btn-option-pack {
    position: absolute;
    right: 5px;
    top: 5px;
    background-image: url('./images/option.png');
    background-size: contain;
    width: 22px;
    height: 22px;
    opacity: 90%;

    cursor: pointer;
}

.btn-option-pack:hover {
    opacity: 50%;
    transition: 350ms;
    width: 25px;
    height: 25px;
    animation: rotation 1s infinite linear;
}

.pack-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 8%;
    width: 100%;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-bottom-dl {
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 500;
    border: 1px solid rgba(130,140,171,0.3);
    border-radius: 10px;
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn-bottom-dl:hover {
    border: 1px solid rgba(55, 59, 71, 0.301);
    transition: 100ms linear;
}

.btn-bottom-dl img {
    height: 40%;
    width: 40%;
    margin-right: -25px;
    margin-left: -25px;
}

.pack-middle {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-height: 430px;
    overflow: scroll;
}

.content-middle {
    width: 80%;
    margin-top: 15px;
    padding: 15px;
    border-radius: 5px;
    background-color: #eeebe5b2;
}

.ls-tab {
    margin-left: 1em;
    color: #3d4a69;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}






.btn-option {
    position: absolute;
    right: 0px;
    top: 0px;
    background-image: url('./images/option.png');
    background-size: contain;
    width: 18px;
    height: 18px;
    opacity: 90%;

    cursor: pointer;
}

.btn-option:hover {
    opacity: 50%;
    transition: 350ms;
}


/* input[type=text] {
    height: 35px;
    width: 300px;
    margin-left: 10px;
    background-color: #394663;
    border: 2px solid #494c52;
    color: white;
    font-size: 1.1rem;
}

input[type=text]::placeholder {
    color: rgba(255, 255, 255, 0.467);
    font-size: 1.1rem;
} */








/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
  scrollbar-width: auto;
  scrollbar-color: #bebebe48 rgba(0,0,0,0);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}

*::-webkit-scrollbar-thumb {
  background-color: #bebebe48;
  border-radius: 10px;
}