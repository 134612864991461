@font-face {
    font-family: "Opificio_Bold";
    src: url("./fonts/Opificio_Bold.ttf") format("truetype");
}

@media only screen and (max-width: 4000px) {
    .login-page {
        height: 100vh;
        width: 100vw;
    }
    
    .bg-lg {
        height: 100%;
        width: 100%;
    }
    
    .bg-left {
        float: left;
        width: 45%;
        height: 100%;
        background-color: #FF424D;
    }
    
    .bg-right {
        float: left;
        width: 55%;
        height: 100%;
        min-width: var(--right-min-size);
        background-color: #FFFFFF;
    }
    
    .login-container {
        z-index: 100;
        position: absolute;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        width: 100vw;
        height: 100vh;
    }
    
    .lgc-left {
        width: 45%;
        height: 100%;
        float: left;
        
        position: relative;
    }
    
    .lgc-content {
        height: 80vh;
        width: 51%;
        background-color: #FF424D;
        color: white;
    
        right: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    
    }
    
    .lgc-content img { 
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    
    .lgc-content h2 {
        margin-top: -3%;
        font-family: Opificio_Bold;
        font-size: 3rem;
    
        color: black;
    }
    
    .lgc-content p {
        margin-top: -8%;
        padding-left: 10%;
        padding-right: 10%;
        font-family: Roboto;
        font-size: 1.2rem;
        color: black;
    }
    
    .lgc-right {
        width: 55%;
        height: 100%;
        float: right;
        
        position: relative;
    }
    
    .lgc-login {
        height: 80vh;
        width: 61%;
        background-color: #FFFFFF;
    
        left: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .lgc-login h3 {
        font-size: 2rem;
        opacity: 80%;
    }
    
    .lgc-form {
        margin: 10%;
    }
    
    .lgc-error {
        color: #ffffff;
        background-color: #f7959a;
        padding: 3px;
    
        display: flex;
        justify-content: space-between;
    }
    
    .errorCross {
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
        height: 100%;
    }
    
    input {
        /* background-color: rgb(240, 230, 230); */
        background-color: #c7c7c72f;
        border: 1px solid #FF424D;
        width: calc(100% - 19px);
        height: 100%;
        padding-left: 15px;
        height: 45px;
        box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
        color: rgb(126, 126, 126);
        font-size: 16px;
    }
    
    input::placeholder {
        color: #f1a8ac;
    }
    
    input::after {
        background-color: rgb(240, 230, 230);
    }
    
    .mg-b {
        margin-bottom: 15px;
    }
    
    .chg-st {
        margin-top: -12px;
        font-size: 13px;
        width: 100%;
        text-align: end;
        margin-bottom: 10px;
    }
    
    .chg-st:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    
    .usr-crtd {
        width: 100%;
        text-align: center;
    }
    
    .bg-shadow {
        width: 56vw;
        height: 80vh;
    
    
        z-index: 50;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        
        box-shadow: 10px 0px 40px rgba(0, 0, 0, 0.74);
    }
    
    button {
        height: 40px;
        cursor: pointer;
        border: none;
        border-radius: 6px;
        font-family: Opificio_Bold;
        font-size: 1.2rem;
    }
    
    button:hover {
        transition: 500ms;
    
        color: black;
        border-left: 10px;
    }
    
    .patreon-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-color: #FF424D;
        color: white;
        width: 50%;
    }
    
    .loadingPatreon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        animation: rotation 2s infinite linear;
    }    
}

@media only screen and (max-width: 1100px) {
    .login-page {
        height: 100vh;
        width: 100vw;
    }
    
    .bg-lg {
        height: 100%;
        width: 100%;
    }
    
    .bg-left {
        float: left;
        width: 45%;
        height: 100%;
        background-color: #FF424D;
    }
    
    .bg-right {
        float: left;
        width: 55%;
        height: 100%;
        min-width: var(--right-min-size);
        background-color: #FFFFFF;
    }
    
    .login-container {
        z-index: 100;
        position: absolute;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        width: 100vw;
        height: 100vh;
    }
    
    .lgc-left {
        width: 45%;
        height: 100%;
        float: left;
        
        position: relative;
    }
    
    .lgc-content {
        height: 80vh;
        width: 51%;
        background-color: #FF424D;
        color: white;
    
        right: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    
    }
    
    .lgc-content img { 
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    
    .lgc-content h2 {
        margin-top: -3%;
        font-family: Opificio_Bold;
        font-size: 2.6vw;
    
        color: black;
    }
    
    .lgc-content p {
        margin-top: -8%;
        padding-left: 10%;
        padding-right: 10%;
        font-family: Roboto;
        font-size: 1.2rem;
        color: black;
    }
    
    .lgc-right {
        width: 55%;
        height: 100%;
        float: right;
        
        position: relative;
    }
    
    .lgc-login {
        height: 80vh;
        width: 61%;
        background-color: #FFFFFF;
    
        left: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .lgc-login h3 {
        font-size: 2rem;
        opacity: 80%;
    }
    
    .lgc-form {
        margin: 10%;
    }
    
    .lgc-error {
        color: #ffffff;
        background-color: #f7959a;
        padding: 3px;
    
        display: flex;
        justify-content: space-between;
    }
    
    .errorCross {
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
        height: 100%;
    }
    
    input {
        /* background-color: rgb(240, 230, 230); */
        background-color: #c7c7c72f;
        border: 1px solid #FF424D;
        width: calc(100% - 19px);
        height: 100%;
        padding-left: 15px;
        height: 45px;
        box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
        color: rgb(126, 126, 126);
        font-size: 16px;
    }
    
    input::placeholder {
        color: #f1a8ac;
    }
    
    input::after {
        background-color: rgb(240, 230, 230);
    }
    
    .mg-b {
        margin-bottom: 15px;
    }
    
    .chg-st {
        margin-top: -12px;
        font-size: 13px;
        width: 100%;
        text-align: end;
        margin-bottom: 10px;
    }
    
    .chg-st:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    
    .usr-crtd {
        width: 100%;
        text-align: center;
    }
    
    .bg-shadow {
        width: 56vw;
        height: 80vh;
    
    
        z-index: 50;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        
        box-shadow: 10px 0px 40px rgba(0, 0, 0, 0.74);
    }
    
    button {
        height: 40px;
        cursor: pointer;
        border: none;
        border-radius: 6px;
        font-family: Opificio_Bold;
        font-size: 1.2rem;
    }
    
    button:hover {
        transition: 500ms;
    
        color: black;
        border-left: 10px;
    }
    
    .patreon-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-color: #FF424D;
        color: white;
        width: 50%;
    }
    
    .loadingPatreon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        animation: rotation 2s infinite linear;
    }    
}

@media only screen and (max-width: 800px) {
    :root {
        --size-left: 25%;
        --size-right: 75%;
    }

    .login-page {
        height: 100vh;
        width: 100vw;
    }
    
    .bg-lg {
        height: 100%;
        width: 100%;
    }
    
    .bg-left {
        float: left;
        width: var(--size-left);
        height: 100%;
        background-color: #FF424D;
    }
    
    .bg-right {
        float: left;
        width: var(--size-right);
        height: 100%;
        min-width: var(--right-min-size);
        background-color: #FFFFFF;
    }
    
    .login-container {
        z-index: 100;
        position: absolute;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        width: 100vw;
        height: 100vh;
    }
    
    .lgc-left {
        width: var(--size-left);
        height: 100%;
        float: left;
        
        position: relative;
    }
    
    .lgc-content {
        height: 80vh;
        width: 100%;
        background-color: #FF424D;
        color: white;
    
        right: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    
    }
    
    .lgc-content img { 
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    
    .lgc-content h2 {
        margin-top: -3%;
        font-family: Opificio_Bold;
        font-size: 2.6vw;
    
        color: black;
    }
    
    .lgc-content p {
        margin-top: -8%;
        padding-left: 10%;
        padding-right: 10%;
        font-family: Roboto;
        font-size: 1.2rem;
        color: black;
    }
    
    .lgc-right {
        width: 55%;
        height: 100%;
        float: right;
    }
    
    .lgc-login {
        height: 80vh;
        width: 100%;
        background-color: #FFFFFF;
    
        left: -20%;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .lgc-login h3 {
        font-size: 2rem;
        opacity: 80%;
    }
    
    .lgc-form {
        margin: 10%;
    }
    
    .lgc-error {
        color: #ffffff;
        background-color: #f7959a;
        padding: 3px;
    
        display: flex;
        justify-content: space-between;
    }
    
    .errorCross {
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
        height: 100%;
    }
    
    input {
        /* background-color: rgb(240, 230, 230); */
        background-color: #c7c7c72f;
        border: 1px solid #FF424D;
        width: calc(100% - 19px);
        height: 100%;
        padding-left: 15px;
        height: 45px;
        box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
        color: rgb(126, 126, 126);
        font-size: 16px;
    }
    
    input::placeholder {
        color: #f1a8ac;
    }
    
    input::after {
        background-color: rgb(240, 230, 230);
    }
    
    .mg-b {
        margin-bottom: 15px;
    }
    
    .chg-st {
        margin-top: -12px;
        font-size: 13px;
        width: 100%;
        text-align: end;
        margin-bottom: 10px;
    }
    
    .chg-st:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    
    .usr-crtd {
        width: 100%;
        text-align: center;
    }
    
    .bg-shadow {
        width: 206vw;
        height: 80vh;
    
        z-index: 50;
        position: absolute;
        top: 50%;
        left: 0;
        margin: 0;
        
        box-shadow: 10px 0px 40px rgba(0, 0, 0, 0.74);
    }
    
    button {
        height: 40px;
        cursor: pointer;
        border: none;
        border-radius: 6px;
        font-family: Opificio_Bold;
        font-size: 1.2rem;
    }
    
    button:hover {
        transition: 500ms;
    
        color: black;
        border-left: 10px;
    }
    
    .patreon-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-color: #FF424D;
        color: white;
        width: 50%;
    }
    
    .loadingPatreon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        animation: rotation 2s infinite linear;
    }    
}

@media only screen and (max-width: 350px) {
    :root {
        --size-left: 25%;
        --size-right: 75%;
    }

    .login-page {
        height: 100vh;
        width: 100vw;
    }
    
    .bg-lg {
        height: 100%;
        width: 100%;
        background-color: #FF424D;
    }
    
    .bg-left {
        display: none;
        float: left;
        width: var(--size-left);
        height: 100%;
        background-color: #FF424D;
    }
    
    .bg-right {
        float: left;
        width: var(--size-right);
        height: 100%;
        min-width: var(--right-min-size);
        background-color: #FFFFFF;
    }
    
    .login-container {
        width: 100vw;
        height: 100vh;
        background-color: #FF424D;
    }
    
    .lgc-left {
        display: none;
    }
    
    .lgc-content {
        display: none;
    }
    
    .lgc-right {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .lgc-login {
        height: 80vh;
        width: 100vw;
        background-color: #FFFFFF;
        position: absolute;
        left: 0;
        box-shadow: 10px 0px 40px rgba(0, 0, 0, 0.397);
    
        margin: 0;
    
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .lgc-login h3 {
        font-size: 2rem;
        opacity: 80%;
    }
    
    .lgc-form {
        margin: 10%;
    }
    
    .lgc-error {
        color: #ffffff;
        background-color: #f7959a;
        padding: 3px;
    
        display: flex;
        justify-content: space-between;
    }
    
    .errorCross {
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
        height: 100%;
    }
    
    input {
        /* background-color: rgb(240, 230, 230); */
        background-color: #c7c7c72f;
        border: 1px solid #FF424D;
        width: calc(100% - 19px);
        height: 100%;
        padding-left: 15px;
        height: 45px;
        box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
        color: rgb(126, 126, 126);
        font-size: 16px;
    }
    
    input::placeholder {
        color: #f1a8ac;
    }
    
    input::after {
        background-color: rgb(240, 230, 230);
    }
    
    .mg-b {
        margin-bottom: 15px;
    }
    
    .chg-st {
        margin-top: -12px;
        font-size: 13px;
        width: 100%;
        text-align: end;
        margin-bottom: 10px;
    }
    
    .chg-st:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    
    .usr-crtd {
        width: 100%;
        text-align: center;
    }
    
    .bg-shadow {
        width: 206vw;
        height: 80vh;
    
        z-index: 50;
        position: absolute;
        top: 50%;
        left: 0;
        margin: 0;
        
        box-shadow: 10px 0px 40px rgba(0, 0, 0, 0.74);
    }
    
    button {
        height: 40px;
        cursor: pointer;
        border: none;
        border-radius: 6px;
        font-family: Opificio_Bold;
        font-size: 1.2rem;
    }
    
    button:hover {
        transition: 500ms;
    
        color: black;
        border-left: 10px;
    }
    
    .patreon-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-color: #FF424D;
        color: white;
        width: 50%;
    }
    
    .loadingPatreon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        animation: rotation 2s infinite linear;
    }    
}